<div class="position-absolute top-0 end-0">
    <div class="alert alert-dismissible fade show" [style.display]="alertVisible ? 'block' : 'none'"
        [ngClass]="{
            'alert-success': alertType === 'success',
            'alert-warning': alertType === 'warning',
            'alert-danger': alertType === 'danger'
        }"
    >
        {{alertMessage}}
      <button type="button" class="btn-close btn-close-white" (click)="closeAlert()" aria-label="Close"></button>
    </div>
</div>