import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  
  public alertVisible: boolean = false;
  public alertType: string = '';
  public alertMessage: string = '';

  public closeAlert(): void {
      this.alertVisible = false;
      this.alertType = '';
      this.alertMessage = '';
  }

  public showAlert(msg: string, type: string, duration: number = 5000): void {
    this.alertVisible = true;
    this.alertMessage = msg;
    this.alertType = type;

    setTimeout(() => {
      this.closeAlert();
    }, duration);
  }
}